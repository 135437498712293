@use '@angular/material' as mat;

@import "@angular/material/theming";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "./tdoc-palette.scss";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($md-tedocdefault, 800);
$app-accent: mat.define-palette($md-tedocdefault, 800);
$app-active: mat.define-palette($md-tedocgreen, 800);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
  ),
  typography: mat.define-typography-config(
    $font-family: "Helvetica Neue",
  )
));

@include mat.all-component-themes($app-theme);
@include mat.slide-toggle-density(-1);

.active {
  color: mat.get-color-from-palette($app-primary);
}

.valid,
.primary {
  color: mat.get-color-from-palette($app-primary);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

form {
  width: 100%;
}

form > * {
  margin-bottom: 1.5em;
  width: 100%;
}

a {
  color: mat.get-color-from-palette($app-primary);
}

.sidenav-content {
  flex-direction: column;
}

.sidenav-container {
  height: 100%;
  padding-bottom: 25px;
}

.app-content {
  flex: 1;
  margin: 0 auto;
  padding: 2em;
}

.cursor-pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.active {
  color: mat.get-color-from-palette($app-active);
}

.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

[class*="flex-"] {
  padding-left: 10px;
  padding-right: 10px;
}

[class*="flex-"]:first-child {
  padding-left: 0;
}

[class*="flex-"]:nth-last-child(2) {
  padding-right: 0;
}

.mat-mdc-card {
  box-sizing: border-box;
}

.mat-mdc-snack-bar-container {
  background-color: mat.get-color-from-palette($app-primary);
  color: white;
}

.mat-mdc-simple-snackbar-action button {
  color: white;
}

.mat-card-actions-spacing {
  gap: 0.5rem;
}

.mat-mdc-mini-fab.mat-primary {
  height: 36px;
  min-width: 64px;
  font-size: 14px;
  border-radius: 4px;
  & mat-icon {
    font-size: 1.125rem;
    height: 1.125rem;
  }
  & .mdc-fab__ripple {
    border-radius: 0;
  }
  & .mat-mdc-button-touch-target {
    min-width: 100%;
  }
}
