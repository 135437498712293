/* For use in src/lib/core/theming/_palette.scss */
$md-tedocdefault: (
  50: #e0eef7,
  100: #b3d4ec,
  200: #80b8df,
  300: #4d9bd2,
  400: #2685c9,
  500: #0070bf,
  600: #0068b9,
  700: #005db1,
  800: #0053a9,
  900: #00419b,
  A100: #c6daff,
  A200: #93b8ff,
  A400: #6097ff,
  A700: #4786ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-tedocgreen: (
  50: #eaf4e6,
  100: #cbe3c0,
  200: #a8d096,
  300: #85bd6c,
  400: #6bae4d,
  500: #51a02d,
  600: #4a9828,
  700: #408e22,
  800: #37841c,
  900: #277311,
  A100: #b8ffa7,
  A200: #8fff74,
  A400: #66ff41,
  A700: #52ff28,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
